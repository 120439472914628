import federatedRedux from 'federated-redux';

interface RuntimeConfig {
  [key: string]: string | boolean | number;
}

interface BuildData {
  version: string;
  date: string;
};

declare global {
  interface Window {
    rootRuntimeConfig: RuntimeConfig,
    rootBuild: BuildData,
    managerRuntimeConfig: RuntimeConfig,
  }
}

function fetchJson<T>(url: string, defaultResult: T, attempt = 1): Promise<T> {
  return fetch(url)
    .then(res => {
      if (res.ok) {
        return res.json();
      }

      if (attempt < 3) {
        console.warn(`Failed to fetch ${url}, running another attempt...`);
        return fetchJson(url, defaultResult, attempt + 1);
      }

      console.error(`Failed to fetch ${url}!`);
      console.error(res.statusText);
      return defaultResult;
    });
};

function fetchBuildData(): Promise<BuildData> {
  return fetchJson<BuildData>('/build.json', { version: 'unknown', date: 'unknown' });
};

// Fetch the runtime config with several attempts
function getRuntimeConfig(attempt = 1): Promise<RuntimeConfig> {
  return fetchJson<RuntimeConfig>('/config.json', {});
};

// Alternatively fetch the runtime config from process.env
function getRuntimeConfigFromEnv() {
  return Promise.resolve({
    API_URL: process.env.API_URL,
    API_V3_URL: process.env.API_V3_URL,
    ELEARNING_URL: process.env.ELEARNING_URL,
    LEARNING_PAGE_URL: process.env.LEARNING_PAGE_URL,
    IMAGES_URL: process.env.IMAGES_URL,
    SURVEY_HUB_API_URL: process.env.SURVEY_HUB_API_URL,
    STATUS_PAGE_URL: process.env.STATUS_PAGE_URL,
    // Deployment
    ENVIRONMENT: process.env.ENVIRONMENT,
    DEPLOY_ENVIRONMENT: process.env.DEPLOY_ENVIRONMENT,
    // Amplitude tracking environment variables
    AMPLITUDE_ACTIVATED: process.env.AMPLITUDE_ACTIVATED,
    AMPLITUDE_VERBOSE: process.env.AMPLITUDE_VERBOSE,
    AMPLITUDE_API_KEY: process.env.AMPLITUDE_API_KEY,
    // Chameleon environment variables
    CHAMELEON_ACTIVE: process.env.CHAMELEON_ACTIVE,
    CHAMELEON_API_KEY: process.env.CHAMELEON_API_KEY,
    CHAMELEON_BLACKLIST: process.env.CHAMELEON_BLACKLIST as string,
    // Sentry
    SENTRY_DSN: process.env.SENTRY_DSN,
    // Admin users
    ENABLE_ADMIN_USERS: process.env.ENABLE_ADMIN_USERS,
    ENABLE_ADMIN_USERS_EDIT: process.env.ENABLE_ADMIN_USERS_EDIT,
  });
}

// Fetch configuration
const runtimeConfigPromise = process.env.USE_RUNTIME_CONFIG === 'true'
  ? getRuntimeConfig()
  : getRuntimeConfigFromEnv();

// Fetch build data
const buildDataPromise = fetchBuildData();

// Load federated redux dispatcher before app boots
const federatedDispatcherPromise = import('Manager/reduxFederatedDispatch').catch(() => {
  console.warn('Failed to load federated dispatcher');
  return { default: () => { console.warn('Federated Redux Dispatch was not loaded') } };
});

Promise.all([runtimeConfigPromise, buildDataPromise, federatedDispatcherPromise])
  .then(([runtimeConfig, buildData, { default: federatedDispatcher }]) => {
    // Set the runtime config for global usage
    window.rootRuntimeConfig = runtimeConfig;

    // Set build data
    window.rootBuild = buildData;

    // Set the federated redux dispatcher for local usage
    federatedRedux.dispatch = federatedDispatcher;

    // Let webpack load federated chunks asynchronously
    import(/* webpackChunkName: "root-bootstrap" */'./bootstrap');
  });

export {};
